export type HandbookCollectionTableCell = string | number | null;

export const HANDBOOK_COLLECTION_TABLE_HEADERS = [
	[
		{
			id: 'id',
			property: 'id',
			title: 'ID',
			resizable: false,
			fixed: false,
			minWidth: 80,
			sort: true,
		},
		{
			id: 'name',
			property: 'name',
			title: 'Название',
			resizable: false,
			fixed: false,
			minWidth: 900,
			sort: true,
		},
	],
];
